<script setup>
import { WdsAppBanner } from '@wds/components';

// ### PROPS
const props = defineProps({
  page: {
    type: String,
    required: true,
  },
  linkPaths: {
    type: String,
    required: true,
  },
  linkParams: {
    type: String,
    default: 'search',
  },
});

// ### CONSTANTS
const APP_BANNER_LINK = 'https://hostelworld.onelink.me/IdiK/vnn7oxyn';

// ### COMPOSABLES
const { isMobileOrTablet } = useDevice();
const {
  dismissAppDownloadBanner,
  isAppDownloadBannerDismissed,
} = useAppDownloadBanner();

// ### DATA
const appBanner = ref(false);

// ### COMPUTED
const showAppDownloadBanner = computed(() => {
  return isMobileOrTablet && appBanner.value;
});

const appDownloadTitle = computed(() => {
  return 't_APPBANNERTITLEPRICING';
});

const appDownloadSubTitle = computed(() => {
  return 't_APPBANNERSUBTITLEPRICING';
});

// ### METHODS
function handleAppBannerDismiss() {
  dismissAppDownloadBanner();
  appBanner.value = false;
}

function handleAppBannerClick() {
  const appDeepLinkPath = props.linkPaths || APP_BANNER_LINK;
  window.location.href = `${appDeepLinkPath}?deep_link_sub10=${btoa(`hw://s/${props.linkParams}`)}`;
}

// ### MOUNTED
onMounted(() => {
  appBanner.value = !isAppDownloadBannerDismissed();
});
</script>

<template>
  <WdsAppBanner
    v-if="showAppDownloadBanner"
    :title="$t(appDownloadTitle)"
    :subtitle="$t(appDownloadSubTitle)"
    :action-button="$t('t_APPBANNEROPEN').toUpperCase()"
    @dismiss-click="handleAppBannerDismiss"
    @banner-click="handleAppBannerClick"
  />
</template>
