export function useAppDownloadBanner() {
  const APP_BANNER_COOKIE_NAME = 'appBannerDismissed';
  const APP_BANNER_MAX_DISMISS = 7 * 86400;

  // ### COMPOSABLES
  const runtimeConfig = useRuntimeConfig();
  const envData = runtimeConfig?.public;

  // ### PROPERTIES
  const appBannerDismissCookie = useCookie(APP_BANNER_COOKIE_NAME, {
    domain: envData?.COOKIE_DOMAIN,
    path: '/',
    sameSite: 'Strict',
    secure: true,
    watch: true,
  });

  const isAppDownloadBannerDismissed = function () {
    if (appBannerDismissCookie.value) {
      const timestamp = Math.floor(Date.now() / 1000);
      return timestamp - appBannerDismissCookie.value < APP_BANNER_MAX_DISMISS;
    }

    return false;
  };

  const dismissAppDownloadBanner = function () {
    appBannerDismissCookie.value = Math.floor(Date.now() / 1000);
  };

  return {
    APP_BANNER_COOKIE_NAME,
    isAppDownloadBannerDismissed,
    dismissAppDownloadBanner,
  };
}
